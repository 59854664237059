const enhanceRecommendation = {
    "SV": [
        {
            "id": "1", 
            "name": "product_cz_sapphire",
            "display": {
                "general": {
                    "name": "product_cz_sapphire_general"
                },
                "rayban": {
                    "name": "product_cz_sapphire_rayban"
                },
                "oakley": {
                    "name": "product_cz_sapphire_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_highest_transparency_cz",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_durable_resistance",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_highest_transparency_cz",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_durable_resistance",
                ],
                "oakley": [
                    "benefit_high_transparency_sharpness",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection",
                ],
            }
        },
        {
            "id": "2",
            "name": "product_cz_rock",
            "display": {
                "general": {
                    "name": "product_cz_rock_general"
                },
                "rayban": {
                    "name": "product_cz_rock_rayban"
                },
                "oakley": {
                    "name": "product_cz_rock_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_durable_resistance",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_durable_resistance",
                ],
                "oakley": [
                    "benefit_high_transparency_sharpness",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection",
                ],
            }
        },
        {
            "id": "3",
            "name": "product_cz_easy_pro",
            "display": {
                "general": {
                    "name": "product_cz_easy_pro_general"
                },
                "rayban": {
                    "name": "product_cz_easy_pro_rayban"
                },
                "oakley": {
                    "name": "product_cz_easy_pro_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_scratch_resistant",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_scratch_resistant",
                ],
                "oakley": [
                    "benefit_reduced_reflection",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection"
                ],
            }
        },
        {
            "id": "4",
            "name": "product_par",
            "display": {
                "general": {
                    "name": "product_par_general"
                },
                "rayban": {
                    "name": "product_par_rayban"
                },
                "oakley": {
                    "name": "product_par_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistant",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_high_transparency_sharpness",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection"
                ],
                "oakley": [
                    "benefit_high_transparency_sharpness",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection",
                ],
            }
        },
        {
            "id": "5",
            "name": "product_sar",
            "display": {
                "general": {
                    "name": "product_sar_general"
                },
                "rayban": {
                    "name": "product_sar_rayban"
                },
                "oakley": {
                    "name": "product_sar_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_uv_backside_protection"
                ],
                "oakley": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_uv_backside_protection"
                ],
            }
        }
    ],
    "PAL": [
        {
            "id": "1",
            "name": "product_cz_sapphire",
            "display": {
                "general": {
                    "name": "product_cz_sapphire_general"
                },
                "rayban": {
                    "name": "product_cz_sapphire_rayban"
                },
                "oakley": {
                    "name": "product_cz_sapphire_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_highest_transparency_cz",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_durable_resistance",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_highest_transparency_cz",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_durable_resistance",
                ],
                "oakley": [
                    "benefit_high_transparency_sharpness",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection",
                ],
            }
        },
        {
            "id": "2",
            "name": "product_cz_rock",
            "display": {
                "general": {
                    "name": "product_cz_rock_general"
                },
                "rayban": {
                    "name": "product_cz_rock_rayban"
                },
                "oakley": {
                    "name": "product_cz_rock_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_durable_resistance",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_durable_resistance",
                ],
                "oakley": [
                    "benefit_high_transparency_sharpness",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection",
                ],
            }
        },
        {
            "id": "3",
            "name": "product_cz_easy_pro",
            "display": {
                "general": {
                    "name": "product_cz_easy_pro_general"
                },
                "rayban": {
                    "name": "product_cz_easy_pro_rayban"
                },
                "oakley": {
                    "name": "product_cz_easy_pro_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_scratch_resistant",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_dust_repellent",
                    "benefit_scratch_resistant",
                ],
                "oakley": [
                    "benefit_reduced_reflection",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection"
                ],
            }
        },
        {
            "id": "4",
            "name": "product_par",
            "display": {
                "general": {
                    "name": "product_par_general"
                },
                "rayban": {
                    "name": "product_par_rayban"
                },
                "oakley": {
                    "name": "product_par_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistant",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_high_transparency_sharpness",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection"
                ],
                "oakley": [
                    "benefit_high_transparency_sharpness",
                    "benefit_easy_to_clean",
                    "benefit_scratch_resistance",
                    "benefit_uv_backside_protection",
                ],
            }
        },
        {
            "id": "5",
            "name": "product_sar",
            "display": {
                "general": {
                    "name": "product_sar_general"
                },
                "rayban": {
                    "name": "product_sar_rayban"
                },
                "oakley": {
                    "name": "product_sar_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_uv_backside_protection"
                ],
                "rayban": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_uv_backside_protection"
                ],
                "oakley": [
                    "benefit_reduced_reflection",
                    "benefit_easy_to_clean",
                    "benefit_uv_backside_protection"
                ],
            }
        }
    ]
}

export default enhanceRecommendation;