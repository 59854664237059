import { getApp } from "../../../store/slices/app.slice";
import { useSelector } from "react-redux";

import APP_CONSTANT from "../../../constants/index.constant";
import useTranslation from "../../../utils/useTranslation";

const AdditionalPairCard = ({ item, index }: any) => {
    const { theme } = useSelector(getApp);
    const { t } = useTranslation(useSelector(getApp));

    return (
        <div className="additional-pair-card">
            <img
                className="additional-pair-card-image"
                src={item?.image?.[theme]}
                alt={t(item?.title)}
            />
            <div className={`badge badge-${theme}`}>
                <div className="dot"></div>
                <span>{t(item?.type === APP_CONSTANT.HIGH ? "badge_highly_recommended" : "badge_recommended")}</span>
            </div>
            <div className="additional-pair-info-container">
                <h2 className={`title-font recom-title-${theme}`}>{t(item?.title)}</h2>
                <p className={`recom-paragraph-${theme}`}>{t(item?.text)}</p>
            </div>
        </div>
    );
};

export default AdditionalPairCard;
