import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import appSlice from './slices/app.slice';
import iRootState from './interface/root-state.interface';

const loadFromLocalStorage = (): iRootState | undefined => {
    try {
        const serialisedState = localStorage.getItem("persistantState");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState) as iRootState;
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

const store = configureStore({
    reducer: {
        app: appSlice.reducer
    },
    preloadedState: loadFromLocalStorage(),
    middleware: [...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })]
});

export default store;