import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSlice, { getApp } from "../../../store/slices/app.slice";
import { useNavigate } from "react-router-dom";
import APP_CONSTANT from "../../../constants/index.constant";
import useTranslation from "../../../utils/useTranslation";

interface Props {
}


const VisionTypeSelection: React.FC<Props> = ({  }) => {
    const { theme } = useSelector(getApp)
    const navigate = useNavigate()    
    const dispatch = useDispatch();

    const [iTheme, setITheme] = useState()
    const { t } = useTranslation(useSelector(getApp));

    useEffect(() => {
        let t: any = theme;
        setITheme(t)        
    },[]);

    useEffect( () => {
        if(iTheme){
            applyUTag(); 
        }
    },[iTheme])
    
    const navigationHandler = (visionType: any) => {              
        dispatch(appSlice.actions.setVisionType(visionType))        
        dispatch(appSlice.actions.setHomeView(1))
        navigate('/questionnaire')   
    }

    const applyUTag = () => {
        const { __uTag }: any = window;
        __uTag && __uTag({     
            "event_name": "page_view",       
            "page_section1": "onboarding",
            "page_section2": "lensselection",
            "screen_title": `${theme}`
        });
    }

    return (
        <div className="modal-container">
            <div className="brand-selection">
                {/* <h5 className={`subtext sub-${theme}`}>Brand</h5> */}
                <h2 className={`title-font normal heading-${theme}`}>{t('vision_type_selection')}</h2>
                <div className="choices-container">
                    <button className={`btn btn-${theme}`} onClick={() => navigationHandler(APP_CONSTANT.PAL)}>{t('progressive_vision')}</button>
                    <button className={`btn btn-${theme}`} onClick={() => navigationHandler(APP_CONSTANT.SV)}>{t('single_vision')}</button>
                </div>
            </div>
        </div>
    );
};

export default VisionTypeSelection;
