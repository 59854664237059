const mainPairRecommendation = {
    "general" : {
        "SV": "reco_page_general_sv",
        "PAL": "reco_page_general_pal",
    },
    "rayban": {
        "SV": {
            "UN_BRANDED": "reco_page_rayban_sv_unbranded",
            "BRANDED": "reco_page_rayban_sv_branded"
        },
        "PAL": {
            "UN_BRANDED": "reco_page_rayban_pal_unbranded",
            "BRANDED": "reco_page_rayban_pal_branded"        
        }
    },
    "oakley": {
        "SV": "reco_page_oakley_sv",
        "PAL": "reco_page_oakley_pal"
    }
}

export default mainPairRecommendation;