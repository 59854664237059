import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getApp } from "../../../store/slices/app.slice";

import RecomBannerGeneral from "../../../assets/png/recom-banner-general.webp";
import RecomBannerRayban from "../../../assets/png/recom-banner-rayban.webp";
import RecomBannerOakley from "../../../assets/png/recom-banner-oakley.webp";
import RomiOfferBanner from "../../../assets/png/romi-offer-banner.webp";

import APP_CONSTANT from "../../../constants/index.constant";
import mainPairRecommendation from "../../../constants/main-pair-recommendation.constant";
import useTranslation from "../../../utils/useTranslation";

const MainPairBanner = ({ correctReco }: any) => {
    const { theme, visionType, earnedPoints } = useSelector(getApp);
    const [mainPairReco, setMainPairReco] = useState<any>({ text: 'NO RECO', coating: [] });
    const { t } = useTranslation(useSelector(getApp));

    const setBannerImage = () => {
        switch (theme) {
            case 'general':
                return RecomBannerGeneral
            case 'rayban':
                return RecomBannerRayban
            case 'oakley':
                return RecomBannerOakley
        }
    }

    const mainPairRecoCalculation = () => {
        
        let data = { text: 'NO RECO', coating: [] };
        let mPR: any = mainPairRecommendation;
        let vt: any = visionType === APP_CONSTANT.PAL ? APP_CONSTANT.PAL : APP_CONSTANT.SV
        if (theme === APP_CONSTANT.GENERAL || theme === APP_CONSTANT.OAKLEY) {
            data = { text: mPR[theme][vt], coating: [] }
        } else if (theme === APP_CONSTANT.OAKLEY) {
            data = { text: mPR[theme][vt], coating: [] }
        } else {
            let b = correctReco?.branded === true ? APP_CONSTANT.BRANDED: APP_CONSTANT.UN_BRANDED;
            data = { text: mPR[theme][vt][b], coating: [] }
        }
        setMainPairReco(data);
    }

    useEffect(() => {
        mainPairRecoCalculation();
        return () => { }
    }, [earnedPoints]);

    return (
        <div className="banner-container">
            <img
                className="banner-image"
                src={correctReco?.name.split("_").includes('romi') ? RomiOfferBanner: setBannerImage()}
                alt="banner"
            />

            <div className="recom-info-container">
                <h2 className={`recom-title-${theme} normal`}>{t('reco_page_main_pair')}</h2>
                <p className={`recom-paragraph-${theme}`}>{t(mainPairReco?.text)}</p>
            </div>
        </div>
    );
};

export default MainPairBanner;
