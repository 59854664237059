import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSlice, { getApp } from "../../../store/slices/app.slice";
import APP_CONSTANT from "../../../constants/index.constant";
import useTranslation from "../../../utils/useTranslation";

interface Props {
    item: any,
    autoNext?: () => void,
    isDisable?: any,
}

const DurationTypeQuestionItem = ({ item, autoNext, isDisable }: Props) => {
    const dispatch = useDispatch();
    const { theme, earnedPoints, questionIndex, visionType } = useSelector(getApp);
    const [selectedItem, setSelectedItem] = useState<any>([]);
    const { t } = useTranslation(useSelector(getApp));

    const handleAnswer = ({ item, option, i: index }: any) => {
        let ept = earnedPoints?.map((item: any) => item);
        ept[item.id - 1] = {
            id: item.id,
            option: index,
            pt: option.pt,
            selected: true
        }
        dispatch(appSlice.actions.setEarnedPoints(ept))
        dispatch(appSlice.actions.setAnsweredIndex(item?.id))
    }

    useEffect(() => {
        if (earnedPoints) {
            let ept = earnedPoints?.map((item: any) => item);
            if (ept[item.id - 1].id === item.id) {
                setSelectedItem("_" + ept[item.id - 1]?.id + ept[item.id - 1]?.option);
            }
        }
    }, [earnedPoints, questionIndex])

    return (
        <li className={`duration-choices ${isDisable ? 'disable' : ''}`}>
            <img src={item?.thumbnail} alt="img" />
            <div className="duration-container">
                <span className={`bold card-text-${theme}`}>{t(item?.title)}</span>
                <ul className="reset-list duration-list">
                    {
                        item?.options?.map((option: any, i: number) => {
                            return (
                                <li key={i}>
                                    <input
                                        id={"outdoor-sport-" + item.id + i}
                                        name={"outdoor-sport-" + item.id}
                                        type="radio"
                                        checked={selectedItem === "_" + item.id + i}
                                        onChange={() => { }}
                                    />
                                    <label className={visionType === APP_CONSTANT.SV ? "small" : ""} htmlFor={"outdoor-sport-" + item.id + i}
                                        onClick={() => {
                                            handleAnswer({ item, option, i })
                                            setSelectedItem(i)
                                            if (autoNext) autoNext()
                                        }}
                                    >{t(option?.name)}</label>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </li>
    )
}

export default DurationTypeQuestionItem;