import iAppStore from "../store/interface/app-store.interface";

type hookType = {
    t: (key: string, fallback?: string) => string,
}

const useTranslation = (app: iAppStore): hookType => {

    const translate = (key: string, fallback?: string): string => {
        if (!app.translation) return key;
        const lang = app.translation;
        if (!lang || !lang?.translation) return key;
        const text = lang.translation[key];
        return text || fallback || key;
    }

    return {
        t: translate,
    }
}

export default useTranslation;