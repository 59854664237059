import React from 'react';
import ReactDOM from 'react-dom';

interface Props {
    text?: string,
    color?: string
}

const LoadingSpinner : React.FC<Props>  = ({ text, color = '#192C50'}) =>  {

    const portal: any = document.getElementById("portal");

    return ReactDOM.createPortal(
        <div className="loading-container">
            <div className="spinner">
                {
                    new Array(12).fill('').map((o,i) => (
                    <div key={i} style={{background: color}}></div>
                    ))
                }
                { text && <span style={{color}} className="progress">{text}</span> }
            </div>
        </div>, portal
    )
}

export default LoadingSpinner;