import { useDispatch, useSelector } from 'react-redux'
import appSlice, { getApp } from '../../../store/slices/app.slice'
import oakleyBG from '../../../assets/png/oakley.webp'
import raybanBG from '../../../assets/png/rayban.webp'
import otherBrandBG from '../../../assets/png/otherbrands.webp'
import Theme from '../../../interface/theme.type'
import { useEffect, useState } from 'react'
import useTranslation from '../../../utils/useTranslation'

interface Props {
}

const BrandSelection: React.FC<Props> = () => {
    const { theme } = useSelector(getApp)
    const dispatch = useDispatch()
    const [iTheme, setITheme] = useState()
    const { t } = useTranslation(useSelector(getApp));
    useEffect(() => {
        let t: any = theme;
        setITheme(t)        
    },[]);

    useEffect( () => {
        if(iTheme){
            applyUTag({screen_title: theme})
        }
    },[iTheme])

    const applyUTag = ({screen_title}: any) => {
        const { __uTag }: any = window;
        __uTag && __uTag({
            "event_name": "session_start",
            "page_section1": "onboarding",
            "page_section2": "frameselection",
            "screen_title": "",            
        });
    }

    const brands = [
        {
            id: 'rayban',
            bgImage: raybanBG
        },
        {
            id: 'oakley',
            bgImage: oakleyBG
        },
        {
            id: 'general',
            bgImage: otherBrandBG
        }
    ]

    return (
        <div className="modal-container">
            <div className="brand-selection">
                <h5 className={`subtext sub-${theme}`}>{t('brand_text')}</h5>
                <h2 className="title-font normal default">{t('brand_selection')}</h2>

                <div className="selection-list">
                    {
                        brands.map(brand => (
                            <img 
                                key={brand.id}
                                src={brand.bgImage}
                                alt={brand.id}
                                className="brand-images"
                                onClick={() => {
                                    // setView(prevCount => prevCount + 1)
                                    dispatch(appSlice.actions.setTheme(brand.id as Theme))
                                    dispatch(appSlice.actions.setHomeView(2))                                    
                                }}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default BrandSelection;
