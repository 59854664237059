type utilsType = {
    sideItems: (index: number) => any;
}

const Utils = (): utilsType => {
    
    const sideItems = (currentIndex: number) => [
        {
            name: "qstn_category_daily_activities", // Q 1, 2, 3
            isSelected: (currentIndex < 1) ? true : false,
        },
        {
            name: "qstn_category_lens_performance", // Q 4, 5
            isSelected: (currentIndex >= 1 && currentIndex <= 2) ? true : false,
        },
        {
            name: "qstn_category_light_sensitivity", // Q 6, 7, 8 
            isSelected: (currentIndex >= 3 && currentIndex <= 5) ? true : false,
        },
        {
            name: "qstn_category_vision_habits", // Q 9, 10, 11, 12
            isSelected: (currentIndex > 5) ? true : false,
        },
    ];
    return {
        sideItems
    }
}

export default Utils;