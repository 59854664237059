import sunPairImage_general from "../assets/png/dedicated-sun-pair-new.webp"
import indoorPairImage_general from "../assets/png/dedicated-indoor-pair.webp"
import sunPairImage_rayban from "../assets/png/sun-pair_rayban.webp"
import indoorPairImage_rayban from "../assets/png/indoor-pair_rayban.webp"
import sunPairImage_oakley from "../assets/png/sun-pair-oakley.webp"
import indoorPairImage_oakley from "../assets/png/indoor-pair-oakley.webp"

const additionalPairRecommendation = {
    "SUN": {
        "image": {
            "general": sunPairImage_general,
            "rayban": sunPairImage_rayban,
            "oakley": sunPairImage_oakley,
        },
        "title": "pair_card_title_sun_pair",
        "text": "pair_card_text_sun_pair"
    },
    "INDOOR": {
        "image": {
            "general": indoorPairImage_general,
            "rayban": indoorPairImage_rayban,
            "oakley": indoorPairImage_oakley,
        },
        "title": "pair_card_title_indoor_pair",
        "text": "pair_card_text_indoor_pair"
    }
}

export default additionalPairRecommendation;