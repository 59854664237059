import { useRef, useState } from "react";
import axios from 'axios';
import { useDispatch } from "react-redux";
import appSlice from "../../store/slices/app.slice";
import LoadingSpinner from "../../components/LoadingSpinner";

const Login = () => {
    const dispatch = useDispatch();
    const [storeId, setStoreId] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [showLoader, setShowLoader] = useState<boolean>(false)
    let loginBtnRef = useRef<HTMLDivElement>(null)

    const rippleEffect = (e: any) => {
        if (loginBtnRef.current) {
            const x = e.pageX - loginBtnRef.current?.offsetLeft
            const y = e.pageY - loginBtnRef.current?.offsetTop
            loginBtnRef.current.style.setProperty('--x', x + 'px')
            loginBtnRef.current.style.setProperty('--y', y + 'px')
        }
    }

    const handleError = (e: string) => {
        setError(e)
        setTimeout( () => {
            setError("")
        },2000)
    }

    const handlLogin = () => {
        if(!storeId) {
            handleError("Store ID is required field.")
        }
        else if(storeId.length !== 4){
            handleError("Store ID must contain 4 characters.")
        }
        else{
            setShowLoader(true)

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://loinstastore-dev.azurewebsites.net/api/v2/stores/s/5-${storeId}`,
                headers: {
                    'app-id': 'com.mediaengine.luxlens',
                    'Authorization': 'Basic bHNzaW06eFljQkpqM1FFejNBbkVWIw=='
                }
            };

            axios.request(config)
                .then((response) => {
                    if(response?.data?.results[0]){
                        localStorage.setItem("auth", JSON.stringify(response?.data?.results[0]))
                        dispatch(appSlice.actions.setHomeView(0))
                        setShowLoader(false)
                    }else{
                        handleError("Invalid Store ID")
                        setShowLoader(false)
                    }
                })
                .catch((error) => {
                    if(error?.response?.status === 400){
                        handleError("Invalid Store ID")
                        setShowLoader(false)
                    }else{
                        setShowLoader(false)
                        handleError("Something went wrong. Please try again later.")
                    }
                });
        }
    }

    return (
        <div className="login-page">
            <div className="card">
                <div className="login-info">
                    <h2 className="heading">Select your Store ID:</h2>
                    <p className="store-id-subtext">Store ID</p>
                    <input placeholder="xxxx" minLength={4} maxLength={4} type="text" value={storeId} onChange={(e) => setStoreId(e.target.value)} />
                    {error && <small className="error">{error}</small>}
                </div>
                <div className="login-button" ref={loginBtnRef} onClick={() => handlLogin()} onMouseEnter={(e) => rippleEffect(e)} >
                    <span>SELECT STORE</span>
                </div>
            </div>
            { showLoader && <LoadingSpinner /> }
        </div>
    )
};

export default Login;
