import IMG_01 from './../assets/png/general/01.webp'
import IMG_02 from './../assets/png/general/02.webp';
import IMG_03_NEW from './../assets/png/general/03_new.webp';
import IMG_04 from './../assets/png/general/04.webp';
import IMG_05 from './../assets/png/general/05.webp';
import IMG_06_NEW from './../assets/png/general/06_new.webp';
import IMG_07_NEW from './../assets/png/general/07_new.webp';
import IMG_08_NEW from './../assets/png/general/08_new.webp';
import IMG_09_NEW_2 from './../assets/png/general/09_new_2.webp';
import IMG_10_NEW_2 from './../assets/png/general/10_new_2.webp';
import IMG_10_A_NEW_2 from './../assets/png/general/10_A_NEW_2.webp'
import IMG_11_NEW from './../assets/png/general/11_new.webp';
import IMG_12 from './../assets/png/general/12.webp';

const raybanQuestions = [
    {
        title: "question_time_wearing_lenses",
        subTitle: "questionnaire_type_duration",
        list: [
            {
                "id": 1,
                "title": "qstn_card_outdoor_sport",
                "thumbnail": IMG_01,
                "options": [
                    {
                        "id": "1_1",
                        "name": "0",
                        "pt": 0
                    },
                    {
                        "id": "1_2",
                        "name": "1-2",
                        "pt": 1
                    },
                    {
                        "id": "1_3",
                        "name": "3-4",
                        "pt": 3
                    },
                    {
                        "id": "1_4",
                        "name": "5+",
                        "pt": 4
                    }
                ]
            },
            {
                "id": 2,
                "title": "qstn_card_outdoor_leisure",
                "thumbnail": IMG_02,
                "options": [
                    {
                        "id": "2_1",
                        "name": "0",
                        "pt": 0
                    },
                    {
                        "id": "2_2",
                        "name": "1-2",
                        "pt": 1
                    },
                    {
                        "id": "2_3",
                        "name": "3-4",
                        "pt": 3
                    },
                    {
                        "id": "2_4",
                        "name": "5+",
                        "pt": 4
                    }
                ]
            },
            {
                "id": 3,
                "title": "qstn_card_indoor_work",
                "thumbnail": IMG_03_NEW,
                "options": [
                    {
                        "id": "3_1",
                        "name": "0",
                        "pt": 0
                    },
                    {
                        "id": "3_2",
                        "name": "1-2",
                        "pt": 1
                    },
                    {
                        "id": "3_3",
                        "name": "3-4",
                        "pt": 3
                    },
                    {
                        "id": "3_4",
                        "name": "5+",
                        "pt": 4
                    }
                ]
            }
        ]
    },
    {
        "id": 4,
        "title": "question_lenses_need_to_be_cleaned",
        "thumbnail": IMG_04,
        "options": [
            {
                "id": "4_1",
                "name": "qstn_answer_strongly_agree",
                "pt": 4
            },
            {
                "id": "4_2",
                "name": "qstn_answer_agree",
                "pt": 3
            },
            {
                "id": "4_3",
                "name": "qstn_answer_disagree",
                "pt": 1
            },
            {
                "id": "4_4",
                "name": "qstn_answer_strongly_disagree",
                "pt": 0
            }
        ]
    },
    {
        "id": 5,
        "title": "question_transparency_of_lenses",
        "thumbnail": IMG_05,
        "options": [
            {
                "id": "5_1",
                "name": "qstn_answer_strongly_agree",
                "pt": 4
            },
            {
                "id": "5_2",
                "name": "qstn_answer_agree",
                "pt": 3
            },
            {
                "id": "5_3",
                "name": "qstn_answer_disagree",
                "pt": 1
            },
            {
                "id": "5_4",
                "name": "qstn_answer_strongly_disagree",
                "pt": 0
            }
        ]
    },
    {
        "id": 6,
        "title": "question_bothered_daylight_outdoors",
        "thumbnail": IMG_06_NEW,
        "options": [
            {
                "id": "6_1",
                "name": "qstn_answer_strongly_agree",
                "pt": 4
            },
            {
                "id": "6_2",
                "name": "qstn_answer_agree",
                "pt": 3
            },
            {
                "id": "6_3",
                "name": "qstn_answer_disagree",
                "pt": 1
            },
            {
                "id": "6_4",
                "name": "qstn_answer_strongly_disagree",
                "pt": 0
            }
        ]
    },
    {
        "id": 7,
        "title": "question_bothered_indoor_lights",
        "thumbnail": IMG_07_NEW,
        "options": [
            {
                "id": "7_1",
                "name": "qstn_answer_strongly_agree",
                "pt": 4
            },
            {
                "id": "7_2",
                "name": "qstn_answer_agree",
                "pt": 3
            },
            {
                "id": "7_3",
                "name": "qstn_answer_disagree",
                "pt": 1
            },
            {
                "id": "7_4",
                "name": "qstn_answer_strongly_disagree",
                "pt": 0
            }
        ]
    },
    {
        "id": 8,
        "title": "question_bothered_sunlight_driving",
        "thumbnail": IMG_08_NEW,
        "options": [
            {
                "id": "8_1",
                "name": "qstn_answer_strongly_agree",
                "pt": 4
            },
            {
                "id": "8_2",
                "name": "qstn_answer_agree",
                "pt": 3
            },
            {
                "id": "8_3",
                "name": "qstn_answer_disagree",
                "pt": 1
            },
            {
                "id": "8_4",
                "name": "qstn_answer_strongly_disagree",
                "pt": 0
            }
        ]
    },
    {
        title: {
            "SV": "question_frequently_during_normal_week",
            "PAL": "question_in_a_typical_day"
        },
        subTitle: "questionnaire_type_duration",
        list: [
            {
                "SV": {
                    "id": 9,
                    "title": "qstn_card_eye_strain",
                    "thumbnail": IMG_10_NEW_2,
                    "options": [
                        {
                            "id": "9_1",
                            "name": "qstn_answer_sometimes",
                            "pt": 0
                        },
                        {
                            "id": "9_2",
                            "name": "qstn_answer_often",
                            "pt": 1
                        },
                        {
                            "id": "9_3",
                            "name": "qstn_answer_frequently",
                            "pt": 3
                        },
                        {
                            "id": "9_4",
                            "name": "qstn_answer_very_frequently",
                            "pt": 4
                        }
                    ]
                },
                "PAL": {
                    "id": 9,
                    "title": "qstn_card_near_distance",
                    "subtext": "qstn_card_near_distance_subheading",
                    "thumbnail": IMG_09_NEW_2,
                    "options": [
                        {
                            "id": "9_1",
                            "name": "qstn_answer_below_2h",
                            "pt": 1
                        },
                        {
                            "id": "9_2",
                            "name": "qstn_answer_2h_to_4h",
                            "pt": 2
                        },
                        {
                            "id": "9_3",
                            "name": "qstn_answer_4h_to_6h",
                            "pt": 3
                        },
                        {
                            "id": "9_4",
                            "name": "qstn_answer_6h_more",
                            "pt": 4
                        }
                    ]
                }
            },
            {
                "SV": {
                    "id": 10,
                    "title": "qstn_card_multiple_device",
                    "thumbnail": IMG_10_A_NEW_2,
                    "options": [
                        {
                            "id": "10_1",
                            "name": "qstn_answer_sometimes",
                            "pt": 0
                        },
                        {
                            "id": "10_2",
                            "name": "qstn_answer_often",
                            "pt": 1
                        },
                        {
                            "id": "10_3",
                            "name": "qstn_answer_frequently",
                            "pt": 3
                        },
                        {
                            "id": "10_4",
                            "name": "qstn_answer_very_frequently",
                            "pt": 4
                        }
                    ]
                },
                "PAL": {
                    "id": 10,
                    "title": "qstn_card_intermediate_distance",
                    "subtext": "qstn_card_intermediate_subheading",
                    "thumbnail": IMG_10_NEW_2,
                    "options": [
                        {
                            "id": "10_1",
                            "name": "qstn_answer_below_2h",
                            "pt": 1
                        },
                        {
                            "id": "10_2",
                            "name": "qstn_answer_2h_to_4h",
                            "pt": 2
                        },
                        {
                            "id": "10_3",
                            "name": "qstn_answer_4h_to_6h",
                            "pt": 3
                        },
                        {
                            "id": "10_4",
                            "name": "qstn_answer_6h_more",
                            "pt": 4
                        }
                    ]
                }
            },
            {
                "SV": {
                    "id": 11,
                    "title": "qstn_card_high_vision",
                    "thumbnail": IMG_07_NEW,
                    "options": [
                        {
                            "id": "11_1",
                            "name": "qstn_answer_sometimes",
                            "pt": 0
                        },
                        {
                            "id": "11_2",
                            "name": "qstn_answer_often",
                            "pt": 1
                        },
                        {
                            "id": "11_3",
                            "name": "qstn_answer_frequently",
                            "pt": 3
                        },
                        {
                            "id": "11_4",
                            "name": "qstn_answer_very_frequently",
                            "pt": 4
                        }
                    ]
                },
                "PAL": {
                    "id": 11,
                    "title": "qstn_card_far_distance",
                    "subtext": "qstn_card_far_distance_subheading",
                    "thumbnail": IMG_11_NEW,
                    "options": [
                        {
                            "id": "11_1",
                            "name": "qstn_answer_below_2h",
                            "pt": 1
                        },
                        {
                            "id": "11_2",
                            "name": "qstn_answer_2h_to_4h",
                            "pt": 2
                        },
                        {
                            "id": "11_3",
                            "name": "qstn_answer_4h_to_6h",
                            "pt": 3
                        },
                        {
                            "id": "11_4",
                            "name": "qstn_answer_6h_more",
                            "pt": 4
                        }
                    ]
                }
            }
        ] 
    },    
    {
        "id": 12,
        "title": "question_blue_uv_filteration",
        "thumbnail": IMG_12,
        "options": [
            {
                "id": "12_1",
                "name": "qstn_answer_strongly_agree",
                "pt": 4
            },
            {
                "id": "12_2",
                "name": "qstn_answer_agree",
                "pt": 3
            },
            {
                "id": "12_3",
                "name": "qstn_answer_disagree",
                "pt": 1
            },
            {
                "id": "12_4",
                "name": "qstn_answer_strongly_disagree",
                "pt": 0
            }
        ]
    }
]

export default raybanQuestions;