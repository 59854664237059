import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import appSlice, { getApp } from "../../store/slices/app.slice";
import QuestionnaireSide from "./components/QuestionnaireSide";
import chevronLeftWhite from "../../assets/svg/chevron-left-white.svg";

import DurationTypeQuestion from "./components/DurationTypeQuestion";
import OpinionTypeQuestion from "./components/OpinionTypeQuestion";

import BottomNavigation from "./components/BottomNavigation";
import raybanQuestions from "./../../constants/rayban-questions.constant";
import oakleyQuestions from "./../../constants/oakley-questions.constant";
import generalQuestions from "./../../constants/general-questions.constant";
import APP_CONSTANT from "../../constants/index.constant";
import LoadingSpinner from "../../components/LoadingSpinner";
import Modal from "../../components/Modal";
import InactivityModal from "./components/InactivityModal";
import QuestionnaireSummary from "./components/QuestionnaireSummary";
import useTranslation from "../../utils/useTranslation";

interface Props { }

const Questionnaire: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { theme, questionIndex, loading, answeredIndex, earnedPoints, homeView } = useSelector(getApp);
    const [questionDataSet, setQuestionDataSet] = useState<any>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(questionIndex !== 0 ? questionIndex : 0);
    const [animateName, setAnimateName] = useState<string>('')
    const [showCheck, setShowCheck] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [showSummaryModal, setShowSummaryModal] = useState(false)
    const { t } = useTranslation(useSelector(getApp));

    // INACTIVITY DETECTION
    useEffect(() => {
        if(homeView === 0) {
            dispatch(appSlice.actions.setBackToInitialState())
            dispatch(appSlice.actions.setHomeView(1))
            navigate("/")
        }
        let userActivity: any;

        const resetUserActivity = () => {
            clearTimeout(userActivity);
            userActivity = setTimeout(() => {
            setIsActive(false)
            }, 180000) // JUMP // millisecond - to be change to 3 min? = to confirmed // temp = 1 min
        }

        const handleUserActivity = () => {
            resetUserActivity();
        };
    
        window.addEventListener('mousedown', handleUserActivity)
        window.addEventListener('mousemove', handleUserActivity)
        
        return () => {
            window.removeEventListener('mousedown', handleUserActivity)
            window.removeEventListener('mousemove', handleUserActivity)
        }
    }, [])

    /* >> - uncomment line 158-164
    1. first - tick mark
    2. second - loading
    3. third - slide question
    */

    // delay timings
    let checkDelay = 300 // 1st
    let loadingDelay = 500; // 2nd
    let slideDelay = 900; // 3rd
    let nextQuestionDelay = 200 // proceed to next question

    useEffect(() => {
        setCurrentIndex(questionIndex ? questionIndex : 0);
    }, [questionIndex]);

    useEffect(() => {
        if (answeredIndex !== 0) {
            setShowCheck(true)
            setTimeout(() => {
                setShowCheck(false)
            }, checkDelay)
        }
    }, [earnedPoints])

    useEffect(() => {
        switch (theme) {
            case APP_CONSTANT.RAYBAN:
                setQuestionDataSet(raybanQuestions[currentIndex]);
                break;
            case APP_CONSTANT.OAKLEY:
                setQuestionDataSet(oakleyQuestions[currentIndex]);
                break;
            default:
                setQuestionDataSet(generalQuestions[currentIndex]);
                break;
        }
        dispatch(appSlice.actions.loading(true))
        setTimeout(() => {
            dispatch(appSlice.actions.loading(false))
        }, loadingDelay)
        return () => { }
    }, [currentIndex]);

    useEffect(() => {
        if ([3, 11].includes(answeredIndex)) {
            setTimeout(() => {
                dispatch(appSlice.actions.setQuestionIndex(currentIndex + 1))
                setAnimateName('slide-from-right')
                setTimeout(() => {
                    setAnimateName('')
                }, slideDelay)
            }, nextQuestionDelay)
        }

        let unansweredQuestion = earnedPoints.filter((ep: any) => ep.selected === false)
        if (unansweredQuestion.length === 0 && questionIndex === 7) {
            setShowSummaryModal(true)
        }
        
    }, [earnedPoints])

    const autoNext = (): void => {
        if ([0, 6, 7].includes(currentIndex)) {
            return
        } else if (answeredIndex >= 1 && answeredIndex <= 11) { // default condition (answeredIndex >= 1 && answeredIndex < 11)
            setTimeout(() => {
                dispatch(appSlice.actions.setQuestionIndex(currentIndex + 1))
                setAnimateName('slide-from-right')
                setTimeout(() => {
                    setAnimateName('')
                }, slideDelay)
            }, nextQuestionDelay)
        } else {
            // JUMP - uncomment below to go back default
            // setTimeout(() => {
            //     navigate("/recommendation")
            // }, nextQuestionDelay)
        }
    }

    const handleBack = () => {
        if (questionIndex === 0) {
            dispatch(appSlice.actions.setBackToInitialState())
            dispatch(appSlice.actions.setHomeView(1))
            navigate("/")
        }

        if (questionIndex) {
            if (questionIndex >= 1) {
                dispatch(appSlice.actions.setQuestionIndex(currentIndex - 1))
                dispatch(appSlice.actions.setAnsweredIndex(0))
            }
            if (questionIndex >= 2) {
                dispatch(appSlice.actions.setAnsweredIndex(answeredIndex - 1))
                setAnimateName('slide-from-left')
                setTimeout(() => {
                    setAnimateName('')
                }, slideDelay)
            }
            if (questionIndex === 7) {
                dispatch(appSlice.actions.setAnsweredIndex(answeredIndex - 3))
                setAnimateName('slide-from-left')
                setTimeout(() => {
                    setAnimateName('')
                }, slideDelay)
            }
        }
    }

    const handleSummaryModal = (value: boolean): void => {
        setShowSummaryModal(value)
    }

    // if (answeredIndex === 12) {
    //     return <QuestionnaireSummary />
    // }

    return (
        <div className="questionnaire-page">
            {/* TICK MARK */}
            <Modal 
                show={showCheck}
            >
                <div className="check">
                    <span>&#10004;</span>
                </div>
            </Modal>
       
            {/* SUMMARY MODAL POP UP */}
            <Modal show={showSummaryModal} bgOverlay={true}>
                <QuestionnaireSummary handleSummaryModal={handleSummaryModal} />
            </Modal>
            
            {/* INACTIVITY MODAL */}
            <Modal show={!isActive} bgOverlay={true}>
            {/* <Modal show={true} bgOverlay={true}> */}
                <InactivityModal toggleModal={() => setIsActive(true)} />
            </Modal>

            <QuestionnaireSide />
            {loading.show && <LoadingSpinner />}
            <div className="questionnaire-container">
                <div className={`top-part ${animateName}`}>
                    {
                        ([0, 6].includes(currentIndex)) &&
                        <DurationTypeQuestion questionDataSet={questionDataSet} autoNext={autoNext} />
                    }
                    {/* {
                        ([6,7,8].includes(currentIndex)) &&
                        <BannerChoice questionDataSet={questionDataSet} autoNext={autoNext} />
                    } */}
                    {
                        ([1, 2, 3, 4, 5, 7].includes(currentIndex)) && questionDataSet?.options &&
                        <OpinionTypeQuestion questionDataSet={questionDataSet} autoNext={autoNext} />
                    }
                    {/* NEXT BTN */}
                    {/* <button className={`btn uppercase bold small next-qst-btn-${theme}`}
                        onClick={handleNext}
                    >
                        Next
                        <img src={chevronRight} alt="next" />
                    </button> */}

                    {/* BACK BTN */}
                    <button className={`btn uppercase bold small next-qst-btn-${theme}`}
                        onClick={handleBack}
                    >
                        <img src={chevronLeftWhite} alt="next" style={{ paddingRight: '6px' }} />
                        {t('btn_back')}
                    </button>
                </div>

                <div className="bottom-part">
                    <BottomNavigation />
                </div>
            </div>
        </div>
    );
};

export default Questionnaire;
