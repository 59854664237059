import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import appSlice, { getApp } from "../../../store/slices/app.slice";
import summary_bg from '../../../assets/png/SummaryBG.webp';
import Icon from "../../../components/Icon";
import { useEffect, useState } from "react";
import APP_CONSTANT, { APP_ANALYTICS } from "../../../constants/index.constant";
import useTranslation from "../../../utils/useTranslation";
import closeIcon from "../../../assets/svg/close-with-bg.svg";

interface Props {
    handleSummaryModal: (value: boolean) => void
}

const QuestionnaireSummary = ({ handleSummaryModal }: Props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { language, theme, visionType, answeredIndex } = useSelector(getApp);
    const [iTheme, setITheme] = useState()
    const { t } = useTranslation(useSelector(getApp));

    useEffect(() => {
        let t: any = theme;
        setITheme(t)
    }, []);

    useEffect(() => {
        if (iTheme) {
            applyUTag();
        }
    }, [iTheme])

    const modifyAnswer = () => {
        applyUTagClick({
            click_label: APP_ANALYTICS.EDIT_ANSWERS,
        });
        dispatch(appSlice.actions.setQuestionIndex(0))
        dispatch(appSlice.actions.setAnsweredIndex(0))
        handleSummaryModal(false)
    }

    const applyUTag = () => {
        const { __uTag }: any = window;
        __uTag && __uTag({
            "event_name": "page_view",
            "page_section1": "questionnaire",
            "page_section2": `completed`,
            "screen_title": `${theme}:${visionType === APP_CONSTANT.PAL ? APP_ANALYTICS.PAL : APP_ANALYTICS.SV}`
        });
    }

    const applyUTagClick = ({ click_label }: any) => {
        const { __uTag }: any = window;
        __uTag && __uTag({
            "event_name": APP_ANALYTICS.CUSTOM_CLICK,
            "page_section1": APP_ANALYTICS.RESULT_PAGE,
            "page_section2": ``,
            "screen_title": theme,
            "click_type": APP_ANALYTICS.BUTTON,
            "click_label": click_label,
            "click_category": APP_ANALYTICS.QUESTIONNAIRE_COMPLETED
        }, APP_ANALYTICS.CLICK);
    }

    const closeSummaryModal = () => {
        handleSummaryModal(false)
        dispatch(appSlice.actions.setAnsweredIndex(answeredIndex - 1))
    }

    return (
        <div className="summary-container">
            <div className={`summary-card container-${theme}`}>
                <div className="img-container">
                    <img src={summary_bg} alt="Summary Card" />
                </div>

                <img 
                    src={closeIcon} 
                    alt="close" 
                    className="close-icon"
                    onClick={closeSummaryModal}
                />

                <div className="option-container">
                    <Icon name={"accept"} folderName='svg' extensionName={"svg"} width={"50"} />
                    <h2 className={language === APP_CONSTANT.FR ? "fr-h2" : ""}>{t('summary_card_qstn_done')}</h2>

                    <button onClick={modifyAnswer}>
                        {t('summary_card_modify_answer')}
                        <Icon
                            name={"chevron-right-blue"}
                            folderName='svg'
                            extensionName="svg"
                            width="8"
                        />
                    </button>

                    <div className="separator">
                        <div></div>
                        <p>{t('summary_card_or')}</p>
                        <div></div>
                    </div>

                    <button onClick={() => {
                        applyUTagClick({
                            click_label: APP_ANALYTICS.GO_TO_RECOMMENDATIONS,
                        });
                        navigate('/recommendation');
                    }
                    }>
                        {t('summary_card_reco_page')}
                        <Icon
                            name={"chevron-right-blue"}
                            folderName='svg'
                            extensionName="svg"
                            width="9"
                        />
                    </button>

                    {/* <p>For associate visibility only</p> */}

                </div>
            </div>
        </div>
    );
};

export default QuestionnaireSummary;
