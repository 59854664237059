import React from "react";
import { useSelector } from "react-redux";
import { getApp } from "../../../store/slices/app.slice";
//general bg imgs
import packageOfferCard1 from '../../../assets/png/varilux-physio-1.webp'
import packageOfferCard2 from '../../../assets/png/varilux-physio-2.webp'
import packageOfferCard3 from '../../../assets/png/varilux-physio-3.webp'
import romi_offer_card from '../../../assets/png/romi-offer-card.webp'

// rayban bg imgs
import offerCard_rayban_1 from '../../../assets/png/offer-card_rayban-1.webp'
import offerCard_rayban_2 from '../../../assets/png/offer-card_rayban-2.webp'
import offerCard_rayban_3 from '../../../assets/png/offer-card_rayban-3.webp'
// oakley bg imgs
import offerCard_oakley_1 from '../../../assets/png/offer-card_oakley-1.webp'
import offerCard_oakley_2 from '../../../assets/png/offer-card_oakley-2.webp'
import offerCard_oakley_3 from '../../../assets/png/offer-card_oakley-3.webp'

import lensesLeftWhite from '../../../assets/svg/lenses-left-white.svg'
//import lensesCenterWhite from '../../../assets/svg/lenses-center-white.svg'
//import lensesRightWhite from '../../../assets/svg/lenses-right-white.svg'

import { COMMERCIAL_TITLE_NAME, COMMERCIAL_BENEFIT_NAME } from "../../../constants/index.constant";
import useTranslation from "../../../utils/useTranslation";

const PackageOfferCard = ({ item, index, protectReco }: any) => {
    const { theme, visionType } = useSelector(getApp)

    // const isPerfectMatch = item?.tag === 'The perfect match' ? true : false
    const isPerfectMatch = item?.tag.split("_").includes("perfect")
    const { t } = useTranslation(useSelector(getApp));

    const offerCardImageHandler = () => {
        const offerBackgroundGeneral = [packageOfferCard1, packageOfferCard2, packageOfferCard3]
        const offerBackgroundRayban = [offerCard_rayban_1, offerCard_rayban_2, offerCard_rayban_3]
        const offerBackgroundOakley = [offerCard_oakley_1, offerCard_oakley_2, offerCard_oakley_3]

        switch (theme) {
            case "general":
                return offerBackgroundGeneral
            case "rayban":
                return offerBackgroundRayban
            case "oakley":
                return offerBackgroundOakley
        }
    }

    return (
        <div className={`package-offer-card ${isPerfectMatch ? `perfect-match-${theme}` : ''}`}>
            <img
                className="package-offer-card-image"
                src={item?.title.split("_").includes('romi') ? romi_offer_card: offerCardImageHandler()[index]}
                alt="package offer"
            />
            <div className={`badge badge-${theme}`}>
                <div className="dot"></div>
                <span>{t(item?.tag)}</span>
            </div>

            {isPerfectMatch && item?.altTag && (
                <div className={`badge-alt badge-${theme}`}>
                    <div className="dot"></div>
                    <span>{t(item?.altTag)}</span>
                </div>
            )}

            <div className="package-info-container">
                <div className="product-name-container">
                    <h3 className={`recom-title-${theme}`}>{t(COMMERCIAL_TITLE_NAME?.[theme]?.title?.[item.title])}</h3>
                </div>

                <div className="lenses-container">
                    <ul className="lenses-list">
                        {
                            item?.benefits?.map((benefit: any, index: number) => {
                                if (index === 1 && !protectReco?.display?.[theme]?.name) {
                                    return <></>
                                } else {
                                    return (
                                        <li key={index} className="list-item">
                                            <img src={lensesLeftWhite} alt="lenses" />
                                            <span className={`span-item-${theme}`}>{index === 1 ? t(protectReco?.display?.[theme]?.name) : t(COMMERCIAL_BENEFIT_NAME?.[visionType][theme]?.[benefit])}</span>
                                        </li>
                                    )
                                }
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PackageOfferCard;
