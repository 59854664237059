const bkt = [
    "benefit_protection_against_uv",
    "benefit_filtering_blue_violet",
];

const trx = [
    "benefit_blue_violet_light_filt",
    "benefit_always_on_protection",
    "benefit_seamlessly_adapt_to_chang",
    "benefit_available_in_wide_range_color",
    "benefit_uv_protection",
]

const protectRecommendation = [
    {
        "id": "1",
        "name": "product_transitions_gen8",
        "display": {
            "general": {
                "name": "product_transitions_gen8_general"
            },
            "rayban": {
                "name": "product_transitions_gen8_rayban"
            },
            "oakley": {
                "name": "product_transitions_gen8_oakley"
            },
        },
        "branded": false,
        "benefits": {
            "general": trx,
            "rayban": trx,
            "oakley": trx,
        }
    },
    {
        "id": "2",
        "name": "product_transitions_xtractive",
        "display": {
            "general": {
                "name": "product_transitions_xtractive_general"
            },
            "rayban": {
                "name": "product_transitions_xtractive_rayban"
            },
            "oakley": {
                "name": "product_transitions_xtractive_oakley"
            },
        },
        "branded": false,
        "benefits": {
            "general": trx,
            "rayban": trx,
            "oakley": trx,
        }
    },
    {
        "id": "3",
        "name": "product_transitions_xtractive_polarized",
        "display": {
            "general": {
                "name": "product_transitions_xtractive_polarized_general"
            },
            "rayban": {
                "name": "product_transitions_xtractive_polarized_rayban"
            },
            "oakley": {
                "name": "product_transitions_xtractive_polarized_oakley"
            },
        },
        "branded": false,
        "benefits": {
            "general": trx,
            "rayban": trx,
            "oakley": trx,
        }
    },
    {
        "id": "4",
        "name": "product_blue_cut",
        "display": {
            "general": {
                "name": "product_blue_cut_general"
            },
            "rayban": {
                "name": "product_blue_cut_rayban"
            },
            "oakley": {
                "name": "product_blue_cut_oakley"
            },
        },
        "branded": false,
        "benefits": {
            "general": bkt,
            "rayban": bkt,
            "oakley": bkt,
        }
    }
]

export default protectRecommendation;