import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getApp } from "../../../store/slices/app.slice";
import chevronRight from '../../../assets/svg/chevron-right.svg'
import Utils from "../../../utils/Utils";
import useTranslation from "../../../utils/useTranslation";

const QuestionnaireSide = () => {
    const { theme, questionIndex, earnedPoints } = useSelector(getApp);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [completion, setCompletion] = useState<number>(0);
    const { sideItems } = Utils();
    const { t } = useTranslation(useSelector(getApp));

    useEffect(() => {
        setCurrentIndex(questionIndex ? questionIndex : 0);
    }, [questionIndex]);

    useEffect(() => {
        let count = 0;
        earnedPoints && earnedPoints?.map((item: any) => {
            if (item.selected === true) {
                count++;
            }
            return item
        })
        setCompletion(Math.round(count / 12 * 100))
    }, [earnedPoints]);

    const si = sideItems(currentIndex);

    return (
        <div className="questionnaire-side">
            <ul className={`side-items-container side-${theme}`}>
                {si.map((item: any) => (
                    <li key={item.name} className={`side-item ${item.isSelected ? `selected-${theme}` : ''}`}>
                        {t(item.name)}
                        <img src={chevronRight} alt="right-icon" />
                    </li>
                ))}
            </ul>

            <div className={`side-progress progress-container-${theme}`}>
                <div className="percentage-container">
                    <p className="progress-text">{t('progress_text_profile')}</p>
                    <p className="percentage-text">{completion}%</p>
                </div>
                <div className={`percentage-bar bar-${theme}`}>
                    <div
                        className={`percentage-fill fill-${theme}`}
                        style={{ width: completion + "%" }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default QuestionnaireSide;
