import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSlice, { getApp } from "../../../store/slices/app.slice";
import useTranslation from "../../../utils/useTranslation";
interface Props {
    questionDataSet: any,
    autoNext?: () => void
}

const OpinionTypeQuestion = ({ questionDataSet, autoNext }: Props) => {
    const dispatch = useDispatch();
    const { theme, earnedPoints, questionIndex, answeredIndex } = useSelector(getApp);
    const [selectedItem, setSelectedItem] = useState<any>('');
    const { t } = useTranslation(useSelector(getApp));
    
    const handleAnswer = ({ item, option, index }: any) => {
        let ept = earnedPoints?.map((item: any) => item);
        ept[item.id - 1] = {
            id: item.id,
            option: index,
            pt: option.pt,
            selected: true
        }
        dispatch(appSlice.actions.setEarnedPoints(ept))
        dispatch(appSlice.actions.setAnsweredIndex(item?.id))
    }
    // useEffect(() => {
    //     if (earnedPoints && questionDataSet?.id) {
    //         let ept = earnedPoints?.map((item: any) => item);
    //         if (ept[questionDataSet?.id - 1]?.id === questionDataSet?.id) {
    //             setSelectedItem("_" + ept[questionDataSet?.id - 1]?.id + ept[questionDataSet?.id - 1]?.option);
    //         }
    //     }
    // }, [earnedPoints, questionIndex])

    return (
        <div className="opinion-type-question">
            <span className={`body-text-${theme}`}>
                {t('questionnaire_type_opinion')}
            </span>

            <h2 className={`title-font qst-title-${theme}`}>
                {t(questionDataSet?.title)}
            </h2>
            {
                (questionDataSet?.thumbnail) && <img className="banner-image" src={questionDataSet?.thumbnail} alt="banner image" />
            }
            <ul className="reset-list opinion-choices-container">
                {
                    questionDataSet?.options?.map((item: any, index: number) => {
                        return (
                            <li key={index}
                                onClick={() => {
                                    handleAnswer({ item: questionDataSet, option: item, index })
                                    if (autoNext) autoNext()
                                }}
                            >
                                <button className={`btn opinion-btn-${theme} ${earnedPoints[answeredIndex]?.option === index ? `opinion-selected-${theme}` : ''}`}>
                                    {t(item?.name)}
                                </button>
                            </li>
                        )
                    })
                }
            </ul>

            {/* <BannerChoice /> */}

        </div>
    );
};

export default OpinionTypeQuestion;
