import { useState } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import appSlice, { getApp } from "../store/slices/app.slice";
import useTranslation from "../utils/useTranslation";
import Language from "../interface/language.interface";
import APP_CONSTANT from "../constants/index.constant";
import USA_FLAG from '../assets/svg/usa.svg';
import FR_FLAG from '../assets/svg/france.svg';

const LanguageSelection = () => {
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation(useSelector(getApp));
    const { language, theme } = useSelector(getApp);
    const dispatch = useDispatch();
    
    const languageSelectionArray = [
        {
            languageName: 'English',
            languageCode: APP_CONSTANT.EN,
            icon: USA_FLAG
        },
        {
            languageName: 'French',
            languageCode: APP_CONSTANT.FR,
            icon: FR_FLAG
        },
    ];

    return (
        <div className="language-selection">
            <div className="info" onClick={() => setShowModal(!showModal)}>
                <h4 className={`language-text-${theme}`}>{language}</h4>
                <div className={`arrow arrow--down`}></div>
            </div>

            <Modal
                show={showModal}
                centerContent={false}
                bgOverlay={false}
                className={"selection-container"}
                hideOnOutsideClick={true}
                closeHandler={() => setShowModal(!showModal)}
            >
                <div className={`container container-radius-${theme}`}>
                    {/* <h4>{t('select_language')}</h4>

                    <select value={language} onChange={e => dispatch(appSlice.actions.setLanguage(e.target.value as Language))}>
                        {
                            languageSelectionArray.map(i => (
                                <option value={i}>{i}</option>
                            ))
                        }
                    </select> */}
                    <div className="top-section">
                        <h4 className={`language-text-${theme}`}>{t('select_language')}</h4>
                    </div>
                    <ul className="language-selection-container">
                        {
                            languageSelectionArray.map((lang, index) => (
                                <li 
                                    key={index}
                                    className={`language-selection-item ${lang.languageCode === language ? "language-selected" : ""}`} 
                                    onClick={() => dispatch(appSlice.actions.setLanguage(lang.languageCode as Language))}
                                >
                                    <div className="box">
                                        <img src={lang.icon} alt={lang.languageCode} />
                                        <p className={`language-text-${theme}`}>{lang.languageCode}</p>
                                    </div>
                                    <div className={`arrow-container ${lang.languageCode === language ? `selected-${theme}` : ""}`}>
                                        <div className="arrow-medium arrow-medium--right"></div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </Modal>
        </div>
    );
};

export default LanguageSelection;
