import { useEffect, useState } from "react";
import { getApp } from "../../../store/slices/app.slice";
import { useSelector } from "react-redux";

const BottomNavigation = () => {
    const { questionIndex, answeredIndex } = useSelector(getApp);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [fillIndex, setFillIndex] = useState<number>(0);
    const statusBars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    useEffect(() => {
        setCurrentIndex(questionIndex ? questionIndex : 0);
    }, [questionIndex]);

    useEffect(() => {
        setFillIndex(answeredIndex ? answeredIndex : 0);
    }, [answeredIndex]);

    return (
        <div className="bottom-navigation-container">
            {/* TEMPORARY HIDDEN */}
            {/* <nav className="bottom-nav-button-container">
                <button className={`bottom-btn bottom-btn-${theme}`} id="previous"
                    onClick={handleBack}
                >
                    <img alt="previous" src={chevronLeft} />
                </button>
                <button className={`bottom-btn bottom-btn-${theme}`} id="next"
                    onClick={handleNext}
                >
                    <img alt="next" src={chevronRightBlue} />
                </button>
            </nav> */}
            
            <div className="huge-progress-bar-container">
            <div className="huge-progress-bar">
                    {
                        statusBars?.map((item: any, index: number) => {
                            return (
                                <div key={index} className="progress-bar">
                                    <div
                                        className="progress"
                                        style={{ width: (fillIndex >= item ? 100 : 0) + "%" }}
                                    ></div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default BottomNavigation;
