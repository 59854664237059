import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iAppStore from '../interface/app-store.interface';
import iRootState from '../interface/root-state.interface';
import Theme from '../../interface/theme.type';
import VisionType from '../../interface/vision.type';
import { TRANSLATIONS_DEFAULT } from '../../constants/translations.contants';
import APP_CONSTANT from '../../constants/index.constant';
import Language from '../../interface/language.interface';
const languageCode = localStorage.getItem('language');

const initialState: iAppStore = {
    loading: {
        show: false
    },
    homeView: 0,
    theme: 'general',
    visionType: "SV",
    questionIndex: 0,
    answeredIndex: 0,
    earnedPoints: [
        { id: 1, option: -1, pt: 0, selected: false },
        { id: 2, option: -1, pt: 0, selected: false },
        { id: 3, option: -1, pt: 0, selected: false },
        { id: 4, option: -1, pt: 0, selected: false },
        { id: 5, option: -1, pt: 0, selected: false },
        { id: 6, option: -1, pt: 0, selected: false },
        { id: 7, option: -1, pt: 0, selected: false },
        { id: 8, option: -1, pt: 0, selected: false },
        { id: 9, option: -1, pt: 0, selected: false },
        { id: 10, option: -1, pt: 0, selected: false },
        { id: 11, option: -1, pt: 0, selected: false },
        { id: 12, option: -1, pt: 0, selected: false },
    ],
    translation: languageCode === APP_CONSTANT.FR ? TRANSLATIONS_DEFAULT.FR: TRANSLATIONS_DEFAULT.EN,
    language: (languageCode || APP_CONSTANT.EN) as Language,
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setBackToInitialState: () => initialState,
        loading: (state, action: PayloadAction<boolean | {
            show: boolean,
            text?: string,
            textOnly?: boolean
        }>) => {
            if (typeof action.payload === 'boolean') {
                return {
                    ...state,
                    loading: {
                        show: action.payload,
                    }
                }
            }

            return {
                ...state,
                loading: action.payload
            }

        },
        setTheme: (state, action: PayloadAction<Theme>) => {
            return {
                ...state,
                theme: action.payload
            }
        },
        setHomeView: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                homeView: action.payload
            }
        },
        setVisionType: (state, action: PayloadAction<VisionType>) => {
            return {
                ...state,
                visionType: action.payload
            }
        },
        setQuestionIndex: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                questionIndex: action.payload
            }
        },
        setAnsweredIndex: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                answeredIndex: action.payload
            }
        },
        setEarnedPoints: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                earnedPoints: action.payload
            }
        },
        setLanguage: (state, action: PayloadAction<Language>) => {
            localStorage.setItem("language", action.payload as Language)
			return {
				...state,
				language: action.payload,
				translation: action.payload === APP_CONSTANT.FR? TRANSLATIONS_DEFAULT.FR: TRANSLATIONS_DEFAULT.EN,
			}
		},
    }
});

export const getApp = (state: iRootState) => state.app;
export default appSlice;
