import { useSelector } from "react-redux";
import { getApp } from "../../../store/slices/app.slice";
import DurationTypeQuestionItem from "./DurationTypeQuestionItem";
import { useState } from "react";
import useTranslation from "../../../utils/useTranslation";

interface Props {
    questionDataSet: any,
    autoNext?: () => void,
}

const DurationTypeQuestion = ({ questionDataSet, autoNext }: Props) => {
    const { theme, answeredIndex, visionType } = useSelector(getApp);
    const [vt, ] = useState<any>(visionType);
    const { t } = useTranslation(useSelector(getApp));

    const isCardDisable = (num: any, answered: any) => {
        if(answered > 3){
            answered-=8;
        }
        if (answered < num) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <h2 className={`title-font qst-title-${theme}`}>
                {t(questionDataSet?.title[vt] ?? questionDataSet?.title)}
            </h2>
            <h3 className={`duration-question duration-qst-${theme}`}>{t(questionDataSet?.subTitle)}</h3>
            <ul className="duration-choices-container">
                {
                    questionDataSet?.list?.map((item: any, index: number) => {
                        return <DurationTypeQuestionItem key={index} item={item[vt] ?? item} autoNext={autoNext} isDisable={isCardDisable(index, answeredIndex)} />
                    })
                }
            </ul>
        </>
    );
};

export default DurationTypeQuestion;
