import React from "react";
import ReactDOM from "react-dom";

interface Props {
    children?: any,
    show?: boolean,
    centerContent?: boolean,
    bgOverlay?: boolean,
    className?: string
    hideOnOutsideClick?: boolean,
    closeHandler?: () => void
}

const Modal = ({
    children, 
    show, 
    centerContent = true,
    bgOverlay,
    className,
    hideOnOutsideClick,
    closeHandler
}: Props) => {
    
    const portal: any = document.getElementById("portal")
    
    const classList = () => {
        let classes: string[] = []
        if (centerContent) classes.push('center-content')
        if (bgOverlay) classes.push('overlay')

        return classes.join(" ")
    }

    const handleClose = () => {
        if (!closeHandler) return
        closeHandler()
    }

    if (!show) return null
    return ReactDOM.createPortal(
        <>
            <div className={`modal-content-container ${classList()} ${className}`}>
            <div className="outside" onClick={handleClose}></div>
                {children}
            </div>
        </>, portal
    )
};

export default Modal;
