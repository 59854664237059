import React from "react";
import appSlice, { getApp } from "../../../store/slices/app.slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../utils/useTranslation";

interface Props {
    toggleModal?: () => void,
}

const InactivityModal = ({ toggleModal }: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const toggleModalHandler = () => {
        if (toggleModal) toggleModal()
    }
    const { t } = useTranslation(useSelector(getApp));

    const newSessionHandler = () => {
        dispatch(appSlice.actions.setBackToInitialState())
        dispatch(appSlice.actions.setHomeView(1))
        navigate("/")
    }

    return (
        <div className="inactivity-modal">
            <h2>{t('inactivity_modal_title')}</h2>
            <div className="buttons-container">
                <button onClick={toggleModalHandler}>{t('inactivity_modal_continue')}</button>
                <button onClick={newSessionHandler}>{t('inactivity_modal_new_session')}</button>
            </div>
        </div>
    );
};

export default InactivityModal;
