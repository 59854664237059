const correctRecommendation = {
    "SV": [
        {
            "id": 1,
            "name": "product_eyezen_boost",
            "display": {
                "general": {
                    "name": "product_eyezen_boost_general",
                },
                "rayban": {
                    "name": "product_eyezen_boost_rayban"
                },
                "oakley": {
                    "name": "product_eyezen_boost_oakley"
                },
            },
            "branded": true,
            "benefits": {
                "general": [ 
                    "benefit_better_readability",
                    "benefit_visual_fatigue",
                    "benefit_improved_contrast",
                    "benefit_blue_violet_light"
                ],
                "rayban": [
                    "benefit_better_readability",
                    "benefit_visual_fatigue",
                    "benefit_improved_contrast",
                    "benefit_blue_violet_light"
                ],
                "oakley": [
                    "benefit_great_clarity",
                    "benefit_accomodative_support_for_easier"
                ],
            }
        },
        {
            "id": 2,
            "name": "product_eyezen_start",
            "display": {
                "general": {
                    "name": "product_eyezen_start_general"
                },
                "rayban": {
                    "name": "product_eyezen_start_rayban"
                },
                "oakley": {
                    "name": "product_eyezen_start_oakley",
                },
            },
            "branded": true,
            "benefits": {
                "general": [
                    "benefit_better_visual_acuity",
                    "benefit_relaxed_eyes",
                    "benefit_improved_contrast",
                    "benefit_blue_violet_light"
                ],
                "rayban": [
                    "benefit_better_visual_acuity",
                    "benefit_relaxed_eyes",
                    "benefit_improved_contrast",
                    "benefit_blue_violet_light"
                ],
                "oakley": [
                    "benefit_great_clarity",
                    "benefit_accomodative_support_for_easier"
                ],
            }
        },
        {
            "id": 3,
            "name": "product_sv_360",
            "display": {
                "general": {
                    "name": "product_sv_360_general"
                },
                "rayban": {
                    "name": "product_sv_360_rayban"
                },
                "oakley": {
                    "name": "product_sv_360_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_great_vision_with_edge",
                    "benefit_etter_contrast"
                ],
                "rayban": [
                    "benefit_great_vision_correction",
                    "benefit_high_vision_resolution",
                    "benefit_optimized_sharpness"
                ],
                "oakley": [
                    "benefit_great_clarity",
                    "benefit_accomodative_support_in_near"
                ],
            }
        },
        {
            "id": 4,
            "name": "product_standard",
            "display": {
                "general": {
                    "name": "product_standard_general"
                },
                "rayban": {
                    "name": "product_standard_rayban"
                },
                "oakley": {
                    "name": "product_standard_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_good_clearity",
                    "benefit_good_value"
                ],
                "rayban": ["benefit_clear_vision_correction"],
                "oakley": ["benefit_good_clearity"],
            }
        }
    ],
    "PAL": [
        {
            "id": 1,
            "name": "product_varilux_physio",
            "display": {
                "general": {
                    "name": "product_varilux_physio_general"
                },
                "rayban": {
                    "name": "product_varilux_physio_rayban"
                },
                "oakley": {
                    "name": "product_varilux_physio_oakley"
                },
            },
            "branded": true,
            "benefits": {
                "general": [
                    "benefit_seamless_transitions",
                    "benefit_sharp_vision_in_all",
                    "benefit_effortless_vision_for",
                    "benefit_wide_reading",
                ],
                "rayban": [
                    "benefit_sharp_vision_in_all",
                    "benefit_smooth_transitions_from",
                    "benefit_effortless_vision_for",
                    "benefit_wide_reading"
                ],
                "oakley": [
                    "benefit_designed_for_best_performance",
                    "benefit_customized_on_behavior",
                    "benefit_customized_on_wearing",
                    "benefit_intermediate_vision"
                ],
            }
        },
        {
            "id": 2,
            "name": "product_varilux_comfort",
            "display": {
                "general": {
                    "name": "product_varilux_comfort_general"
                },
                "rayban": {
                    "name": "product_varilux_comfort_rayban"
                },
                "oakley": {
                    "name": "product_varilux_comfort_oakley" 
                },
            },
            "branded": true,
            "benefits": {
                "general": [
                    "benefit_sharp_vision_without_struggle",
                    "benefit_all_day_long_visual_comfort_as",
                    "benefit_you_will_get_used_to",
                ],
                "rayban": [
                    "benefit_all_day_long_visual_comfort",
                    "benefit_improved_postural_flexi",
                    "benefit_wide_reading",
                    "benefit_easy_adaptation"
                ],
                "oakley": [
                    "benefit_designed_for_best_performance",
                    "benefit_customized_on_behavior",
                    "benefit_customized_on_wearing",
                    "benefit_intermediate_vision"
                ],
            }
        },
        {
            "id": 3,
            "name": "product_snl7a",
            "display": {
                "general": {
                    "name": "product_snl7a_general"
                },
                "rayban": {
                    "name": "product_snl7a_rayban"
                },
                "oakley": {
                    "name": "product_snl7a_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_superior_vision_at",
                    "benefit_personalized_for_right",
                    "benefit_intermediate_vision"
                ],
                "rayban": [
                    "benefit_superior_vision_at",
                    "benefit_designed_for_best_performance",
                    "benefit_intermediate_vision"
                ],
                "oakley": [
                    "benefit_great_vision_at_all_dist",
                    "benefit_high_visual_sharpness",
                    "benefit_intermediate_vision",
                ],
            }
        },
        {
            "id": 4,
            "name": "product_snl6",
            "display": {
                "general": {
                    "name": "product_snl6_general"
                },
                "rayban": {
                    "name": "product_snl6_rayban"
                },
                "oakley": {
                    "name": "product_snl6_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_good_balance_of_vision",
                    "benefit_good_value"
                ],
                "rayban": [
                    "benefit_great_vision_at_all_dist",
                    "benefit_balance_from_far_to_near",
                    "benefit_intermediate_vision"
                ],
                "oakley": [
                    "benefit_good_vision_at_all_dist",
                    "benefit_contrast_and_sharp_vision",
                    "benefit_intermediate_vision"
                ],
            }
        },
        {
            "id": 5,
            "name": "product_fbs04a",
            "display": {
                "general": {
                    "name": "product_fbs04a_general"
                },
                "rayban": {
                    "name": "product_fbs04a_rayban"
                },
                "oakley": {
                    "name": "product_fbs04a_oakley"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_great_vision_at_all_dist",
                    "benefit_customized_on_wearing",
                    "benefit_intermediate_vision"
                ],
                "rayban": [
                    "benefit_good_vision_at_all_dist",
                    "benefit_wide_fields_of_vision",
                    "benefit_intermediate_vision"
                ],
                "oakley": [
                    "benefit_great_vision_at_all_dist",
                    "benefit_high_visual_sharpness",
                    "benefit_intermediate_vision"
                ],
            }
        },
        {
            "id": 6,
            "name": "product_romi",
            "display": {
                "general": {
                    "name": "product_romi_general"
                },
                "rayban": {
                    "name": "product_romi_general"
                },
                "oakley": {
                    "name": "product_romi_general"
                },
            },
            "branded": false,
            "benefits": {
                "general": [
                    "benefit_effortless_focus",
                    "benefit_clear_vision",
                    "benefit_tailored_unique",
                    "benefit_approved_for_smartphone",
                ],
                "rayban": [
                    "benefit_effortless_focus",
                    "benefit_clear_vision",
                    "benefit_tailored_unique",
                    "benefit_approved_for_smartphone",
                ],
                "oakley": [
                    "benefit_effortless_focus",
                    "benefit_clear_vision",
                    "benefit_tailored_unique",
                    "benefit_approved_for_smartphone",
                ],
            }
        }
    ]
}

export default correctRecommendation;