import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSlice, { getApp } from "../../../store/slices/app.slice";
import chevronRight from "../../../assets/svg/chevron-right.svg";
import chevronLeft from "../../../assets/svg/chevron-left.svg";
import acceptIcon from "../../../assets/svg/accept.svg";
import declineIcon from "../../../assets/svg/decline.svg";
import useTranslation from "../../../utils/useTranslation";

interface Props {
}

const ModalIntro: React.FC<Props> = () => {
    const dispatch = useDispatch()
    const firstSlideRef = useRef<HTMLDivElement>(null);
    const secondSlideRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation(useSelector(getApp));

    const slideHandler = (divRef: React.RefObject<HTMLDivElement>) => {
        if (divRef.current) {
            divRef.current.scrollIntoView()
        }
    }

    return (
        <main className="modal-container">
            <div className="carrousel">
                <div className="slide" id="slide01" ref={firstSlideRef}>
                    <span className="page-number">1/2</span>
                    <div>
                        <h2 className="title-font big uppercase">
                            {t('personalize_your_lenses')}
                        </h2>
                        <p className="paragraph">
                            {t('lens_advisor_intro_lense_crafter')}
                        </p>
                    </div>

                    <div className="btns-container">
                        <button
                            className="blue-btn uppercase bold"
                            onClick={() => slideHandler(secondSlideRef)}
                        >
                            {t('btn_next')}
                            <img
                                alt="next"
                                src={chevronRight}
                                style={{ marginLeft: "2rem" }}
                            />
                        </button>
                    </div>
                </div>
                <div className="slide" id="slide02" ref={secondSlideRef}>
                    <div className="page-number-container">
                        <button className="btn" onClick={() => slideHandler(firstSlideRef)}>
                            <img alt="Go back" src={chevronLeft} />
                        </button>
                        <span className="page-number">2/2</span>
                    </div>

                    <div>
                        <h2 className="title-font big uppercase">
                            {t('personalize_your_lenses')}
                        </h2>
                        <p className="paragraph">
                            {t('lens_advisor_intro_purpose')}
                        </p>
                    </div>
                    <div className="btns-container">
                        <button className="blue-btn uppercase bold" onClick={() => slideHandler(firstSlideRef)}>
                            {t('btn_decline')}
                            <img
                                alt="Decline"
                                src={declineIcon}
                                style={{ marginLeft: "2rem" }}
                            />
                        </button>
                        <button
                            className="blue-btn uppercase bold"
                            // onClick={() => setView((prevView) => prevView + 1)}
                            onClick={() => dispatch(appSlice.actions.setHomeView(1))}
                        >
                            {t('btn_accept')}
                            <img
                                alt="Accept"
                                src={acceptIcon}
                                style={{ marginLeft: "2rem" }}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ModalIntro;
