import { useDispatch, useSelector } from "react-redux";
import appSlice, { getApp } from "../../store/slices/app.slice";
import ModalIntro from "./components/ModalIntro";
import BrandSelection from "./components/BrandSelection";
import VisionTypeSelection from "./components/VisionTypeSelection";
import Login from "../login/Login";
import { useEffect } from "react";

interface Props { }

const Home: React.FC<Props> = () => {

    const dispatch = useDispatch();
    const { homeView } = useSelector(getApp)
    const auth = localStorage.getItem("auth");

    useEffect( () => {
        if(auth === null || auth === undefined){
            dispatch(appSlice.actions.setHomeView(-1))
        }
    },[auth]);

    const viewHandler = (view: number) => {
        switch (view) {
            case 0:
                return <ModalIntro />
            case 1:
                return <BrandSelection />
            case 2:
                return <VisionTypeSelection />
            default:
                return <Login/>
        }
    }    

    return (
        <>
            <div className="home-page">
                {viewHandler( homeView )}
            </div>
        </>
    );
};

export default Home;
