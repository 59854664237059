import { useSelector } from "react-redux";
import { getApp } from "../../../store/slices/app.slice";
import closeIcon from "../../../assets/svg/close-with-bg.svg";
import { useEffect, useState } from "react";
import APP_CONSTANT from "../../../constants/index.constant";
import raybanQuestions from "../../../constants/rayban-questions.constant";
import oakleyQuestions from "../../../constants/oakley-questions.constant";
import generalQuestions from "../../../constants/general-questions.constant";
import useTranslation from "../../../utils/useTranslation";

interface Props {
    closeModal?: () => void,
    headingCategory?: string,
    headingIcon?: any,
    productName?: string,
    headingIndex?: number
}

const SeeAnswerModal = ({ closeModal, headingCategory, headingIcon, productName, headingIndex }: Props) => {
    const { theme, visionType, earnedPoints } = useSelector(getApp)
    const [questions, setQuestions] = useState<any>();
    const { t } = useTranslation(useSelector(getApp));

    useEffect(() => {

        const questionDataSet = theme === APP_CONSTANT.RAYBAN ? raybanQuestions :
            theme === APP_CONSTANT.OAKLEY ? oakleyQuestions : generalQuestions;

        switch (headingIndex) {
            case 0:
                // 9, 10, 11
                const a = questionDataSet[6];
                setQuestions(a)
                break;
            case 1:
                // 6, 7, 8, 12
                const b = [
                    questionDataSet[3],
                    questionDataSet[4],
                    questionDataSet[5],
                    questionDataSet[7]
                ];
                setQuestions(b)
                break;
            case 2:
                // 4 ,5 
                const c = [
                    questionDataSet[1],
                    questionDataSet[2]
                ];
                setQuestions(c)
        }
    }, [headingIndex])

    const closeHandler = () => {
        if (!closeModal) return
        closeModal()
    }

    return (
        <div className="see-answer-modal">
            <div className="answer-heading">
                <div className="benefit-info">
                    <div className={`lens-type tag-${theme}`}>
                        <img src={headingIcon} alt="heading icon" />
                        <p className={`pair-title-${theme}`}>{headingCategory}</p>
                    </div>
                    <h1 className={`main-recom-title-${theme}`}>{productName}</h1>
                </div>
                <button className={`close close-${theme}`} onClick={closeHandler}>
                    <img src={closeIcon} alt="close" />
                </button>
            </div>

            <div className={`answers-content-container ${theme}-scrollbar`}>
                {
                    headingIndex === 0 ?
                        <>
                            {
                                questions?.list?.map((it: any, index: number) => {
                                    const vt: any = visionType;
                                    const item: any = it[vt]
                                    return (
                                        <div key={index} className="summary-item">
                                            <h3 className={`product-pair-name-${theme}`}>{t(questions?.title[vt])} {t(item?.title)}</h3>
                                            <div className="answers-container">
                                                {
                                                    item?.options?.map((optoin: any, i: number) => {
                                                        return (
                                                            <p key={i} className={`bullet-point-${theme} tag-${earnedPoints[item?.id - 1]?.option === i ? theme : 'any'}`}>{t(optoin?.name)}</p>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                            {
                                questions?.map((item: any, index: number) => {
                                    return (
                                        <div key={index} className="summary-item">
                                            <h3 className={`product-pair-name-${theme}`}>{t(item?.title)}</h3>
                                            <div className="answers-container">
                                                {
                                                    item?.options?.map((optoin: any, i: number) => {
                                                        return (
                                                            <p key={i} className={`bullet-point-${theme} tag-${earnedPoints[item?.id - 1]?.option === i ? theme : 'any'}`}>{t(optoin?.name)}</p>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>


                }


            </div>
        </div>
    );
};

export default SeeAnswerModal;
