import React, { useState, useEffect, useRef } from "react";

interface Props {
    name: string;
    folderName: string,
    extensionName?: "svg" | "png"; // for folder and extension
    classModifier?: string;
    click?: () => void;
    width?: string;
    height?: string;
}

const Icon: React.FC<Props> = ({
    name,
    folderName,
    extensionName,
    classModifier,
    width,
    height,
    click,
}) => {
    const [image, setImage] = useState<any>();

    const clickHandler = () => {
        if (click) click();
    };

    const classHandler = () => {
        if (classModifier) {
            return classModifier;
        }
        return
    };

    const widthAndHeightHandler = () => {
        if (width || height) {
            return {
                height: `${height}px`,
                width: `${width}px`,
            }
        }   
        return
    }

    const renderIcon = () => {
        return (
            <img 
                src={image} 
                alt="Icon" 
                onClick={clickHandler}
                className={classHandler()} 
                style={widthAndHeightHandler()}
            />
        )
    };

    let mounting = useRef(false);
    useEffect(() => {
        mounting.current = true;
        const loadImage = (imgName: string) => {
            import(`../assets/${folderName}/${imgName}.${extensionName}`)
                .then((img) => {
                    if (mounting.current) {
                        setImage(img.default);
                    }
                })
                .catch(() => console.log(`Image not found: ${imgName}`));
        };
        loadImage(name);

        return () => {
            mounting.current = false;
        };
    }, [name, folderName, extensionName]);

    return (
        <>
            {image && renderIcon()}
        </>
    );
};

export default Icon;
