import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import appSlice from '../store/slices/app.slice'
import { getApp } from '../store/slices/app.slice'
import { useLocation, useNavigate } from 'react-router-dom'
import LensCrafterLogo from '../assets/svg/lens-crafters-logo.svg'
import RaybanLogo from '../assets/svg/logo-rayban.svg'
import OakleyLogo from '../assets/svg/logo-oakley.svg'
import BackIcon from './ui/BackIcon'
import RestartIcon from './ui/RestartIcon'
import Utils from '../utils/Utils'
import APP_CONSTANT, { APP_ANALYTICS } from '../constants/index.constant'
import LanguageSelection from './LanguageSelection'
import useTranslation from '../utils/useTranslation'

const Navbar = () => {
    const { theme, questionIndex, answeredIndex, visionType } = useSelector(getApp)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const scrollToTopRef = useRef<HTMLDivElement>(null)
    const { sideItems } = Utils()
    const location = useLocation();
    const { t } = useTranslation(useSelector(getApp));

    useEffect(() => {
        const auth = localStorage.getItem("auth");
        if (auth === null || auth === "" || !auth) {
            navigate("/")
        }
    }, [])

    useEffect(() => {
        if (location.pathname.search("questionnaire") !== -1) {
            applyUTag()
        }
    }, [location, questionIndex])

    const applyUTag = () => {
        if (questionIndex === 6 && answeredIndex === 11) {
            // TO: fix bug
        } else if (questionIndex < 8) {
            const si = sideItems(questionIndex);
            const category = si.find((item: any) => item.isSelected === true);
            const pageSection1 = t(category?.name).replace(/\s/g, "")
            const { __uTag }: any = window;
            __uTag && __uTag({
                "event_name": "page_view",
                "page_section1": pageSection1,
                "page_section2": `question${questionIndex + 1}`,
                "screen_title": `${theme}:${visionType === APP_CONSTANT.PAL ? APP_ANALYTICS.PAL : APP_ANALYTICS.SV}`
            });
        }
    }

    const navbarThemeHandler = () => { // TODO
        if (theme === 'oakley') {
            return {
                img: OakleyLogo,
                buttonText: `Oakley Mad Science`,
                deepLink: 'oohub://',
            }
        } else if (theme === 'rayban') {
            return {
                img: RaybanLogo,
                buttonText: `Rayban Lenses App`,
                deepLink: 'roxus://',
            }
        } else {
            return {
                img: LensCrafterLogo,
                buttonText: 'Lens Simulator',
                deepLink: 'lenssim://',
            }
        }
    }
    const navbarSetting = navbarThemeHandler()

    const restart = () => {
        const auth = localStorage.getItem("auth");
        if (auth) {
            applyUTagClick(
                {
                    click_label: APP_ANALYTICS.RESTART,
                    click_category: APP_ANALYTICS.RESULT_PAGE
                }
            )
            dispatch(appSlice.actions.setBackToInitialState())
            dispatch(appSlice.actions.setHomeView(1))
            //navigate("/")
            window.location.reload(); 
        }
    }

    const backToLastQuestion = () => {
        applyUTagClick(
            {
                click_label: APP_ANALYTICS.BACK,
                click_category: APP_ANALYTICS.RESULT_PAGE
            }
        )
        dispatch(appSlice.actions.setQuestionIndex(6))
        dispatch(appSlice.actions.setAnsweredIndex(11))
        navigate("/questionnaire")
    }

    const isInRecoPage = window.location.pathname.split("/").includes("recommendation")

    useEffect(() => {
        if (scrollToTopRef.current) {
            scrollToTopRef.current.scrollIntoView()
        }
    }, [questionIndex])

    const applyUTagClick = ({ click_label, click_category }: any) => {
        const { __uTag }: any = window;
        __uTag && __uTag({
            "event_name": APP_ANALYTICS.CUSTOM_CLICK,
            "page_section1": APP_ANALYTICS.RESULT_PAGE,
            "page_section2": ``,
            "screen_title": theme,
            "click_type": APP_ANALYTICS.BUTTON,
            "click_label": click_label,
            "click_category": click_category
        }, APP_ANALYTICS.CLICK);
        //console.log(click_label)
    }

    const getAnalyticsTheme = (t: any) => {
        switch (t) {
            case 'general':
                return APP_ANALYTICS.GENERAL
            case 'oakley':
                return APP_ANALYTICS.OAKLEY
            case 'rayban':
                return APP_ANALYTICS.RAYBAN
        }
    }

    return (
        <div className="navbar" ref={scrollToTopRef}>
            <img className="brand-logo" src={navbarSetting.img} alt="brand logo" onClick={restart} />
            <h2 className={`title title-${theme}`}>{t('app_name')}</h2>

            <div className="buttons-container">
                {
                    isInRecoPage && (
                        <>
                            <button
                                className={`back-button btn bold small next-qst-btn-${theme}`}
                                onClick={backToLastQuestion}
                            >
                                <span>{t('btn_back')}</span> <BackIcon />
                            </button>

                            <button
                                className={`restart-button btn bold small next-qst-btn-${theme} next-qst-btn-${theme}`}
                                onClick={restart}
                            >
                                <span>{t('btn_restart')}</span> <RestartIcon />
                            </button>

                            <a href={navbarSetting.deepLink} onClick={() => applyUTagClick(
                                {
                                    click_label: getAnalyticsTheme(theme),
                                    click_category: APP_ANALYTICS.OPEN
                                }
                            )}>
                                <button className={`deep-link-btn btn bold small next-qst-btn-${theme}`}>
                                    {navbarSetting.buttonText}
                                </button>
                            </a>
                        </>
                    )
                }

                {!isInRecoPage && <LanguageSelection />}
            </div>
        </div>
    )
}

export default Navbar
