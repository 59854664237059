
const routes = {
    home: {
        id: 'home',
        path: '/',
        label: 'home'
    },
    questionnaire: {
        id: 'questionnaire',
        path: '/questionnaire',
        label: 'questionnaire'
    },
    recommendation: {
        id: 'recommendation',
        path: '/recommendation',
        label: 'recommendation'
    },
}

export default routes;