import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import routes from './constants/routes.constant';
import Home from "./pages/home/Home";
import Navbar from "./components/Navbar";
import Questionnaire from "./pages/questionnaire/Questionnaire";
import Recommendation from "./pages/recommendation/Recommendation";
import Preloader from "./components/Preloader";
import AppVersion from "./components/AppVersion";

//test line updated

function App() {
  const [isAppReady, setIsAppReady] = useState(false)
  
  useEffect(() => {
    let isLocalhost = checkIstLocalHost()

    if (isLocalhost) {
      setIsAppReady(true) // set to true when the app is running on localhost
    } else { // if in live site, wait until the serviceWorker is ready
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
        .then((registration) => {
          console.log('Service Worker is ready:', registration);
          setIsAppReady(true)
        })
        .catch((error) => {
          console.error('Error getting Service Worker registration:', error);
        });
      } 
    }
  }, [])

  function checkIstLocalHost() {
    // check if the current url is localhost
    return /^(http:\/\/)?localhost:\d+/.test(window.location.href);
  }

  return (
    <div className="App">
      {
        isAppReady ? (
          <Provider store={store}>
            <Router>
              
              <Navbar />
    
              <Routes>
                <Route path={routes.home.path} Component={Home} />
                <Route path={routes.questionnaire.path} Component={Questionnaire} />
                <Route path={routes.recommendation.path} Component={Recommendation} />
              </Routes>
            </Router>
          </Provider>
        ) : (
          <Preloader />
        )
      }
      <AppVersion />
    </div>
  );
}

export default App;
