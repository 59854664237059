const Preloader = () => {
    return (
        <div className="preloader">
            <h1>Loading</h1>

            { /* loader */ }
            <div className="line-loader">
                <div className="indicator"></div>
            </div>
        </div>
    )
};

export default Preloader;
